import React from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Thankyouscreen = () => {
  return (
    <>
      <div className='text-center thankyou-page pt-5'>
        <h1>" Thank You "</h1>
        <h2>We appreciate your interest</h2>
        <p>
          Your message / inquiries are important to us and we will respond as
          soon as possible
        </p>
        <LinkContainer to='/'>
          <Button className='thankyou-btn'>Go To Home Page</Button>
        </LinkContainer>
      </div>
    </>
  );
};

export default Thankyouscreen;
