import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";

import Sarah_ward from "../assets/clients/sarah_ward.jpeg";

const Carouselscreen = () => {
  return (
    <>
      <div className="carousel-section">
        <Container>
          <Carousel fade>
            <Carousel.Item>
              <Row>
                <Col md={5} sx={12}>
                  <img
                    className="d-block w-100 carousel_img"
                    src={Sarah_ward}
                    alt="First slide"
                  />
                </Col>
                <Col md={7} sx={12} className="carousel_text">
                  <div>
                    <h3>Sarah Ward</h3>
                    <h4>Fine & Coastal Real Estate</h4>
                    <div className="carousel_icons">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <p>
                      "Support Owl has helped me place several VAs for my
                      various companies. Support Owl does a great job finding
                      and interviewing quality candidates for me to interview.
                      The company takes great care to understand the job
                      requirements and qualities I am looking for in a new hire.
                      I can be confident that anyone that the company brings to
                      interview with me, is very well qualified. It's clear that
                      Support Owl puts in a lot of work behind the scenes
                      finding the best candidates. I would highly recommend
                      Support Owl to anyone looking to place a new VA!! I will
                      definitely use Support Owl and team again in the future!""
                    </p>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
            {/* <Carousel.Item>
              <img
                className="d-block w-100"
                src={reviews2}
                alt="Second slide"
              />

              <Carousel.Caption>
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="holder.js/800x400?text=Third slide&bg=20232a"
                alt="Third slide"
              />

              <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption>
            </Carousel.Item> */}
          </Carousel>
        </Container>
      </div>
    </>
  );
};

export default Carouselscreen;
