import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Rating from './Rating';

const CardComponent = ({ review }) => {
  return (
    <>
      <div className='card_container'>
        <Row>
          <Col md={4} className='card_section1'>
            <Card className='card_image_container'>
              <Card.Img variant='top' src={review.image} />
            </Card>
          </Col>
          <Col md={8} className='card_section2'>
            <div className='card_section_text_content'>
              <h3>
                <strong>{review.client_name}</strong>
              </h3>
              <h4> {review.position}</h4>

              {/* <h4>{review.client_company}</h4> */}

              <Rating value={review.rating} />

              <p>"{review.review}"</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CardComponent;
