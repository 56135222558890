import React from 'react';
import { Container } from 'react-bootstrap';

const SubscriptionThankyou = () => {
  return (
    <>
      <Container>
        <h1>Subscription Thank you page</h1>
      </Container>
    </>
  );
};

export default SubscriptionThankyou;
