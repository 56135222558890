import React from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import service7 from "../assets/services/service7.jpg";
import service8 from "../assets/services/service8.jpg";
import service9 from "../assets/services/service9.jpg";

import { motion } from "framer-motion";

import { PopupWidget } from "react-calendly";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

function Modal1(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">ADMIN VIRTUAL ASSISTANT</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul className="va_modal_ul">
            <li>Intake of new patients and referrals details</li>
            <li>Appointment setting</li>
            <li>Calendar management</li>
            <li>Organizing files in your database</li>
            <li>Supply management </li>
            <li>Medical billing and coding</li>
            <li>Follow-ups and checking on patients after appointments</li>
            <li>Prescription refills</li>
            <li>Email management</li>
            <li>Transferring physical forms into a digital format</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ServicesMedical = () => {
  const [modalShow1, setModalShow1] = React.useState(false);

  return (
    <>
      <Container>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="services_main_container"
        >
          <PopupWidget
            url="https://calendly.com/supportowlph/virtual-assistant-consultation?month=2023-05"
            rootElement={document.getElementById("root")}
            text="Set Appointment"
            textColor="#fff"
            color="#178B86"
          />
          <h1>Medical Virtual Assistants</h1>
          <div className="admin_desktop">
            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service7}
                  alt="first slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>Medical Admin Virtual Assistant</h2>
                <ul>
                  <li>Intake of new patients and referrals details</li>
                  <li>Appointment setting</li>
                  <li>Calendar management</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow1(true)}
                >
                  View Details
                </Button>
                <Modal1 show={modalShow1} onHide={() => setModalShow1(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12} className="services_text">
                <h2>Medical Scribe/Transcriber</h2>
                <ul>
                  <li>Chart examinations in real time or via recording</li>
                </ul>
              </Col>
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service8}
                  alt="first slide"
                />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service9}
                  alt="first slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>Receptionist</h2>
                <ul>
                  <li>
                    Take in-bound calls and messages, transfer urgent calls as
                    needed Handle all communication via email, text, live chat
                    and phone Calendar management
                  </li>
                </ul>
              </Col>
            </Row>
          </div>

          <div className="admin_mobile">
            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service7}
                  alt="first slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>Medical Admin Virtual Assistant</h2>
                <ul>
                  <li>Intake of new patients and referrals details</li>
                  <li>Appointment setting</li>
                  <li>Calendar management</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow1(true)}
                >
                  View Details
                </Button>
                <Modal1 show={modalShow1} onHide={() => setModalShow1(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service8}
                  alt="first slide"
                />
              </Col>

              <Col md={6} xs={12} className="services_text">
                <h2>Medical Scribe/Transcriber</h2>
                <ul>
                  <li>Chart examinations in real time or via recording</li>
                </ul>
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service9}
                  alt="first slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>Receptionist</h2>
                <ul>
                  <li>
                    Take in-bound calls and messages, transfer urgent calls as
                    needed Handle all communication via email, text, live chat
                    and phone Calendar management
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </motion.div>
      </Container>
    </>
  );
};

export default ServicesMedical;
