import React from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { PopupWidget } from "react-calendly";

import cs1 from "../assets/cs1.jpg";
import cs2 from "../assets/cs2.jpg";
import cs3 from "../assets/cs3.jpg";
import cs4 from "../assets/cs4.jpg";
import cs5 from "../assets/cs5.jpg";
import cs6 from "../assets/cs6.jpg";
import cs7 from "../assets/cs7.jpg";
import cs8 from "../assets/cs8.jpg";
import cs9 from "../assets/cs9.jpg";

import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

const Servicesscreen = () => {
  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <PopupWidget
          url="https://calendly.com/supportowlph/virtual-assistant-consultation?month=2023-05"
          rootElement={document.getElementById("root")}
          text="Set Appointment"
          textColor="#fff"
          color="#178B86"
        />
        <div className="services-container">
          <div className="services-text">
            <h4>Get Yourself A Virtual Assistant</h4>

            <p>
              Our primary goal is to ensure that you and your business can keep
              up with the daily challenges that your business experiences.
              Offloading your tasks to our virtual assistants allows you to
              delegate tasks that consume a lot of your precious time enabling
              you to focus on what is important to maintain a good flow in the
              operation of your business.
            </p>
          </div>
        </div>

        <Container>
          <div className="services">
            <h1 className="services-header">
              We offer the following outsourcing solutions:
            </h1>

            <Row>
              <Col sm={12} md={6} className="first-row">
                <Card className="my-3 p-3 rounded services-cards">
                  <Card.Title as="div" className="text-center">
                    <h3>REAL ESTATE VIRTUAL ASSISTANT</h3>
                  </Card.Title>
                  <Card.Img src={cs2} variant="top" alt="first image" />
                  <Card.Body>
                    <Card.Text>
                      <ul>
                        <h3>LISTING MANAGER</h3>
                        <li>Manage all aspects of sellers transactions</li>
                        <li>
                          Prepares all listing materials: pre-listing
                          presentation, listing agreement, sellers' disclosure,
                          etc
                        </li>
                        <li>
                          Provide proactive weekly feedback to sellers regarding
                          all marketing activities
                        </li>
                        <li>
                          Coordinate with all public open houses and open broker
                          houses
                        </li>
                        <li>
                          Update all listing information into the marketing
                          websites
                        </li>
                        <li>
                          Submit all necessary documents to the office broker as
                          needed
                        </li>
                        <li>
                          Input/update all necessary information into client
                          database and transaction management systems
                        </li>
                        <h3 className="mt-3">TRANSACTION COORDINATOR</h3>
                        <li>
                          Manage all aspects of buyer and seller transactions
                          from purchase agreement to closure
                        </li>
                        <li>
                          Coordinate inspections and assist in negotiations
                        </li>
                        <li>
                          Regular update and maintain communication with
                          clients, agents, title officers, and lenders
                        </li>
                        <li>
                          Provide all necessary documents to office broker for
                          file compliance
                        </li>
                        <li>Input/update client database</li>
                        <li>Schedule and attend the closing process</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <LinkContainer to="/contact">
                    <Button>Hire Now!</Button>
                  </LinkContainer>
                </Card>
              </Col>

              <Col sm={12} md={6} className="first-row">
                <Card className="my-3 p-3 rounded services-cards">
                  <Card.Title as="div" className="text-center">
                    <h3>PROPERTY MANAGEMENT VIRTUAL ASSISTANT</h3>
                  </Card.Title>
                  <Card.Img src={cs1} variant="top" />
                  <Card.Body>
                    <Card.Text>
                      <ul>
                        <h3>MAINTENANCE COORDINATOR</h3>
                        <li>
                          Conduct business in a manner that attracts and retains
                          residents
                        </li>
                        <li>
                          Guide tenants to perform basic trouble shooting before
                          assigning to vendors
                        </li>
                        <li>
                          Receives, distributes, processes and tracks work
                          orders for maintenance and repair of leased properties
                        </li>
                        <li>
                          Coordinate repair updates and schedules with Tenants,
                          Vendors and Owners
                        </li>
                        <li>
                          Keep detailed records of all related charges for rent
                          deductions, as required
                        </li>
                        <li>
                          Responsible for vendor and tenant follow-up,
                          call-backs, resolving and rescheduling pending work
                          orders, when appropriate
                        </li>

                        <h3 className="mt-3">PROPERTY ASSISTANT MANAGER</h3>
                        <li>Coordinates with all parties involved</li>
                        <li>Review and Prepare lease documents</li>
                        <li>Perform tenant background checks</li>
                        <li>Schedule move in/out dates</li>
                        <li>
                          Computes, Coordinates and applies per diem charges
                          based on lease agreement
                        </li>
                        <li>
                          Track records of all related charges for rent
                          deductions
                        </li>
                        <li>
                          Keep accurate and details reports of property/tenant
                          issues
                        </li>
                        <li>
                          Diplomatically handle and diffuse sensitive, difficult
                          and disruptive situations that may arise
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <LinkContainer to="/contact">
                    <Button>Hire Now!</Button>
                  </LinkContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6} className="first-row">
                <Card className="my-3 p-3 rounded services-cards">
                  <Card.Title as="div" className="text-center">
                    <h3>REAL ESTATE INSIDE SALES AGENT</h3>
                  </Card.Title>
                  <Card.Img src={cs9} variant="top" />
                  <Card.Body>
                    <Card.Text>
                      <ul>
                        <li>
                          Communicating with leads, making an outbound call to
                          potential customers, and follow-ups
                        </li>
                        <li>Researching and qualifying old and new leads</li>
                        <li>Upselling products and services</li>
                        <li>Closing of sales/deals</li>
                        <li>
                          Researching about competing products and services
                        </li>
                        <li>
                          Responsible for demonstrating key features of products
                          and services
                        </li>
                        <li>
                          Understanding customers' specific needs and providing
                          options for sales opportunities
                        </li>
                        <li>
                          Answering potential leads' inquiries and sending
                          additional options via email
                        </li>
                        <li>Responsible for product and service updates</li>
                        <li>
                          Creating a lead database of current and potential
                          leads
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <LinkContainer to="/contact">
                    <Button>Hire Now!</Button>
                  </LinkContainer>
                </Card>
              </Col>

              <Col sm={12} md={6} className="second-row">
                <Card className="my-3 p-3 rounded services-cards">
                  <Card.Title as="div" className="text-center">
                    <h3>MEDICAL VIRTUAL ASSISTANT</h3>
                  </Card.Title>
                  <Card.Img src={cs4} variant="top" />
                  <Card.Body>
                    <Card.Text>
                      <ul>
                        <h3>ADMIN VIRTUAL ASSISTANT</h3>
                        <li>Intake of new patients and referrals details</li>
                        <li>Appointment setting</li>
                        <li>Calendar management</li>
                        <li>Organizing files in your database</li>
                        <li>Supply management</li>
                        <li>Medical billing and coding</li>
                        <li>
                          Follow-ups and checking on patients after appointments
                        </li>
                        <li>Prescription refills</li>
                        <li>Email management</li>
                        <li>
                          Transferring physical forms into a digital format
                        </li>
                        <h3 className="mt-3">MEDICAL SCRIBE/TRANSCRIBER</h3>
                        <li>
                          Chart examinations in real time or via recording
                        </li>
                        <h3 className="mt-3">RECEPTIONIST</h3>
                        <li>
                          Take in-bound calls and messages, transfer urgent
                          calls as needed Handle all communication via email,
                          text, live chat and phone Calendar management
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <LinkContainer to="/contact">
                    <Button>Hire Now!</Button>
                  </LinkContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6} className="second-row">
                <Card className="my-3 p-3 rounded services-cards">
                  <Card.Title as="div" className="text-center">
                    <h3>GENERAL VIRTUAL ASSISTANT</h3>
                  </Card.Title>
                  <Card.Img src={cs8} variant="top" />
                  <Card.Body>
                    <Card.Text>
                      <ul>
                        <li>Email management and handle phone calls</li>
                        <li>Book travel and accommodations</li>
                        <li>Prepare customer spreadsheets</li>
                        <li>Perform market research</li>
                        <li>Create feature presentations</li>
                        <li>Handle employee administrative queries</li>
                        <li>Provide customer service</li>
                        <li>Data entry and database management</li>
                        <li>
                          Scheduling and managing appointments and calendars
                        </li>
                        <li>
                          Arrange meetings and sending of invitations to team
                          members
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <LinkContainer to="/contact">
                    <Button>Hire Now!</Button>
                  </LinkContainer>
                </Card>
              </Col>

              <Col sm={12} md={6} className="second-row">
                <Card className="my-3 p-3 rounded services-cards">
                  <Card.Title as="div" className="text-center">
                    <h3>MARKETING VIRTUAL ASSISTANT</h3>
                  </Card.Title>
                  <Card.Img src={cs3} variant="top" />
                  <Card.Body>
                    <Card.Text>
                      {" "}
                      <ul>
                        <li>
                          Assisting the marketing team with project organization
                        </li>
                        <li>
                          Performing administrative tasks related to marketing
                          activities
                        </li>
                        <li>
                          Conducting marketing research and analyzing obtained
                          data
                        </li>
                        <li>
                          Integration of marketing analytics to an existing
                          project
                        </li>
                        <li>Update database, inventory, and spreadsheets</li>
                        <li>
                          Preparation of promotional presentations and events
                        </li>
                        <li>
                          Updating content for the company's social media and
                          website
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <LinkContainer to="/contact">
                    <Button>Hire Now!</Button>
                  </LinkContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6} className="third-row">
                <Card className="my-3 p-3 rounded services-cards">
                  <Card.Title as="div" className="text-center">
                    <h3>ACCOUNTING VIRTUAL ASSISTANT</h3>
                  </Card.Title>
                  <Card.Img src={cs5} variant="top" />
                  <Card.Body>
                    <Card.Text>
                      <ul>
                        <li>
                          Compute, classify and record financial documents
                        </li>
                        <li>
                          Check accuracy of document figures before finalizing
                        </li>
                        <li>
                          Manage all entries and updates of all transactions
                        </li>
                        <li>Paying/updating company bills</li>
                        <li>Create/prepare general ledgers and invoices</li>
                        <li>Organize financial statements</li>
                        <li>Manage employee reimbursements</li>
                        <li>Tax Management</li>
                      </ul>

                      <p className="list-p">
                        Manage reconcile statements such as:
                      </p>

                      <ul>
                        <li>Credit cards</li>
                        <li>Vendor's and client's statements</li>
                        <li>Bank account statements</li>
                        <li>
                          Process accounts payable and receivable transactions
                        </li>
                      </ul>

                      <p className="list-p">
                        Prepare financial statements such as:
                      </p>
                      <ul>
                        <li>Trial balance</li>
                        <li>Profit and loss account</li>
                        <li>Balance sheet</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <LinkContainer to="/contact">
                    <Button>Hire Now!</Button>
                  </LinkContainer>
                </Card>
              </Col>

              <Col sm={12} md={6} className="third-row">
                <Card className="my-3 p-3 rounded services-cards">
                  <Card.Title as="div" className="text-center">
                    <h3>CUSTOMER SUPPORT/SERVICE</h3>
                  </Card.Title>
                  <Card.Img src={cs6} variant="top" />
                  <Card.Body>
                    <Card.Text>
                      <ul>
                        <li>
                          Responding to customers' queries via phone or other
                          means of communication
                        </li>
                        <li>
                          Returning phone calls on clients' behalf, with or
                          without guided scripts
                        </li>
                        <li>Checking and responding to voicemail</li>
                        <li>Proofreading and editing email responses.</li>
                        <li>Organizing and filtering customers' emails</li>
                        <li>
                          Engaging with customers and prospects on social media
                          platforms by responding to comments and messages.
                        </li>
                        <li>Setting up support desk via call or chat</li>
                        <li>
                          Guiding customers through the sales process via call,
                          chat, or email.
                        </li>
                        <li>
                          Identifying opportunities for upselling and
                          cross-selling with customers.
                        </li>
                        <li>
                          Building and nurturing strong relationships with
                          long/short term customers or clients
                        </li>
                        <li>Keeping track of important dates and schedules</li>
                        <li>
                          Tactfully handling customer complaints and concerns.
                        </li>
                        <li>
                          Resolving escalated cases and forwarding them to the
                          next person in charge.
                        </li>
                        <li>
                          Keeping records of customer details needed for sales
                          opportunities.
                        </li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                  <LinkContainer to="/contact">
                    <Button>Hire Now!</Button>
                  </LinkContainer>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </motion.div>
    </>
  );
};

export default Servicesscreen;
