import React from 'react';
import { Container, Form, Button, Tabs, Tab } from 'react-bootstrap';
import { motion } from 'framer-motion';

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};
const ReviewFormScreen = () => {
  return (
    <motion.div variants={containerVariants} initial='hidden' animate='visible'>
      <Container>
        <div className='reviewform_container'>
          <h1>Your feedback is important to us.</h1>
          <p>We accept feedbacks from both our clients and VA's</p>
        </div>
        <div className='tabs_container'>
          <Tabs
            defaultActiveKey='client'
            id='uncontrolled-tab-example'
            className='mb-3'
            justify
          >
            <Tab eventKey='client' title='Client'>
              <p className='reviewform_header'>Client Form</p>
              <Form
                action='https://formsubmit.co/info@supportowlph.com'
                method='POST'
                enctype='multipart/form-data'
              >
                <input
                  type='hidden'
                  name='_next'
                  value='https://www.supportowlph.com/thankyoureview'
                />
                <input type='hidden' name='_captcha' value='false' />
                <input
                  type='hidden'
                  name='_subject'
                  value='Client Review'
                ></input>

                <Form.Group className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='Client Full Name'
                    name='name'
                    required
                  />
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='Job Title/Position'
                    name='position'
                    required
                  />
                </Form.Group>

                {/* <Form.Group className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='Company'
                    name='company'
                    required
                  />
                </Form.Group> */}

                <div className='my-4'>
                  <input
                    type='file'
                    name='profile_image'
                    accept='image/png, image/jpeg, image/jpg'
                    required
                  ></input>
                  <Form.Text className='text-muted'>
                    Upload your profile picture here.
                  </Form.Text>
                </div>

                <Form.Group className='mb-3'>
                  <Form.Label>Review / Testimonial:</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={4}
                    placeholder=''
                    name='review'
                    required
                  />
                </Form.Group>

                <Button variant='primary' type='submit'>
                  Submit
                </Button>
              </Form>
            </Tab>
            <Tab eventKey='va' title='Virtual Assistant'>
              <p className='reviewform_header'>virtual assistant form </p>
              <Form
                action='https://formsubmit.co/info@supportowlph.com'
                method='POST'
                enctype='multipart/form-data'
              >
                <input
                  type='hidden'
                  name='_next'
                  value='https://www.supportowlph.com/thankyoureview'
                />
                <input type='hidden' name='_captcha' value='false' />
                <input type='hidden' name='_subject' value='VA Review'></input>

                <Form.Group className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='VA Full Name'
                    name='name'
                    required
                  />
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='Job Title/Position'
                    name='position'
                    required
                  />
                </Form.Group>

                {/* <Form.Group className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='Company'
                    name='company'
                    required
                  />
                </Form.Group> */}

                <div className='my-4'>
                  <input
                    type='file'
                    name='profile_image'
                    accept='image/png, image/jpeg, image/jpg'
                    required
                  ></input>
                  <Form.Text className='text-muted'>
                    Upload your profile picture here.
                  </Form.Text>
                </div>

                <Form.Group className='mb-3'>
                  <Form.Label>Review / Testimonial:</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={4}
                    placeholder=''
                    name='review'
                    required
                  />
                </Form.Group>

                <Button variant='primary' type='submit'>
                  Submit
                </Button>
              </Form>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </motion.div>
  );
};

export default ReviewFormScreen;
