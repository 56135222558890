import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footerscreen from '../screens/Footerscreen';

const Footer = () => {
  return (
    <>
      <footer>
        <Footerscreen />
        <Container>
          <Row>
            <Col className='text-center py-3'>
              All Rights Reserved Support Owl PH | Copyright &copy;
              supportowlph.com 2023
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
