import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import { InlineWidget } from "react-calendly";

const ContactForm = () => {
  return (
    <>
      <Container>
        {/* <div className="form">
          <Form
            action="https://formsubmit.co/info@supportowlph.com"
            method="POST"
          >
            <input
              type="hidden"
              name="_next"
              value="https://www.supportowlph.com/thankyou"
            />
            <input type="hidden" name="_captcha" value="false" />
            <Form.Group className="mb-3">
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Full Name" name="name" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  name="email"
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Contact Number"
                  name="contact"
                  max-length="12"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                />
                <Form.Text className="text-muted">
                  Format: 123-456-7890
                </Form.Text>
              </Form.Group>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Questions / Inquiries:</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder=""
                name="message"
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div> */}
        <InlineWidget url="https://calendly.com/supportowlph/virtual-assistant-consultation?month=2023-05" />
      </Container>
    </>
  );
};

export default ContactForm;
