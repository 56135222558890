import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import Logo2 from "../assets/so-logo2.png";
import { LinkContainer } from "react-router-bootstrap";

import { motion } from "framer-motion";

const header = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <Navbar
          bg="light"
          variant="light"
          expand="lg"
          collapseOnSelect
          className="py-3 px-3"
          sticky="top"
          id="top"
        >
          <Container>
            <LinkContainer to="/">
              <a href="#top">
                <Navbar.Brand className="logo-mobile">
                  {" "}
                  <img
                    alt=""
                    src={Logo2}
                    width="160"
                    height="40"
                    className="d-inline-block align-top"
                  />{" "}
                </Navbar.Brand>
              </a>
            </LinkContainer>
            <LinkContainer to="/">
              <a href="#top">
                <Navbar.Brand className="logo-desktop mx-3">
                  {" "}
                  <img
                    alt=""
                    src={Logo2}
                    width="300"
                    height="65"
                    className="d-inline-block align-top"
                  />{" "}
                </Navbar.Brand>
              </a>
            </LinkContainer>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto text-center">
                <LinkContainer to="/">
                  <Nav.Link className="nav-links">Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about">
                  <Nav.Link className="nav-links">About</Nav.Link>
                </LinkContainer>

                <NavDropdown
                  title="SERVICES"
                  id="basic-nav-dropdown"
                  className="dropdown_link"
                >
                  <LinkContainer to="/realestate">
                    <NavDropdown.Item>REAL ESTATE</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/propertymanagement">
                    <NavDropdown.Item>PROPERTY MANAGEMENT</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/medical">
                    <NavDropdown.Item>MEDICAL VA</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/administrative">
                    <NavDropdown.Item>OTHER VA ROLES</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <LinkContainer to="/contact">
                  <Nav.Link className="nav-links">Contact</Nav.Link>
                </LinkContainer>
                {/* <LinkContainer to='/reviews'>
                <Nav.Link className='nav-links mr-3'>Reviews</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/privacypolicy'>
                <Nav.Link className='nav-links mr-3'>Privacy Policy</Nav.Link>
              </LinkContainer> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </motion.div>
    </>
  );
};

export default header;
