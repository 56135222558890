import React from "react";
import { Button, Row, Col, Card, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Logo3 from "../assets/so-logo3.png";

const Footerscreen = () => {
  return (
    <>
      <div className="lower-section text-center">
        <h1>What are you waiting for?</h1>
        <h3>
          Take your business to the next level with a top-notch, highly skilled,
          professional virtual assistant.{" "}
        </h3>
        <LinkContainer to="/contact">
          <Button className="lower-section-btn mt-3">Start Hiring Now!</Button>
        </LinkContainer>
      </div>

      <div className="footer-list">
        <Row>
          <Col md={3}>
            <Card className="footer-card">
              <LinkContainer to="/">
                <Card.Img src={Logo3} variant="top" />
              </LinkContainer>
            </Card>
            <p className="text-center footer-para">
              Accelerate your business without breaking the bank.
            </p>
          </Col>

          <Col md={4} className="footer-links">
            <h1>Services</h1>

            <Nav className="flex-column footer-nav">
              <LinkContainer to="/services">
                <Nav.Link>Real Estate VA</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link>Property Management VA</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link>Real Estate Inside Sales Agent</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link>Medical VA</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link>General VA</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link>Marketing VA</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link>Accounting VA</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/services">
                <Nav.Link>Customer Support/Service</Nav.Link>
              </LinkContainer>
            </Nav>
          </Col>

          <Col md={4} className="footer-links">
            <h1>Other Links</h1>
            <Nav className="flex-column footer-nav">
              <LinkContainer to="/about">
                <Nav.Link>About SupportOwl</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link>Help Desk</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link>Questions & Inquiries</Nav.Link>
              </LinkContainer>
            </Nav>
          </Col>

          {/* <Col md={3} className="footer-links">
            <div className='social-media'>
              <h1>Connect With Us</h1>
              <ul>
                <li>
                  <i class='fab fa-facebook-f'></i>
                </li>
                <li>
                  <i class='fab fa-instagram'></i>
                </li>
                <li>
                  <i class='far fa-envelope'></i>
                </li>
              </ul>
            </div>
            <h1>Terms of use</h1>

            <Nav defaultActiveKey="/home" className="flex-column footer-nav">
              <LinkContainer to="/privacypolicy">
                <Nav.Link>Privacy Policy</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/privacypolicy">
                <Nav.Link>Security of Personal Data</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/privacypolicy">
                <Nav.Link>Children's Privacy</Nav.Link>
              </LinkContainer>
            </Nav>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default Footerscreen;
