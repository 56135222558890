const reviews = [
  {
    id: 1,
    client_name: 'Sarah Ward',
    client_company: 'Fine & Coastal Real Estate',
    position: 'Real Estate Agent / Start-Up Founder',
    image: './images/sarah_ward.jpeg',
    rating: 5,
    review:
      "Support Owl has helped me place several VAs for my various companies. Support Owl does a great job finding and interviewing quality candidates for me to interview. The company takes great care to understand the job requirements and qualities I am looking for in a new hire. I can be confident that anyone that the company brings to interview with me, is very well qualified. It's clear that Support Owl puts in a lot of work behind the scenes finding the best candidates. I would highly recommend Support Owl to anyone looking to place a new VA!! I will definitely use Support Owl and team again in the future!",
  },
  // {
  //   id: 2,
  //   client_name: 'Steve Miller',
  //   client_company: 'Top Realty Corporation',
  //   position: 'Sales Manager',
  //   image: './images/profile2.jpg',
  //   rating: 4.5,
  //   review:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into .",
  // },
];

export default reviews;
