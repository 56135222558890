import React from 'react';
import { motion } from 'framer-motion';
import { Button, Container, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import reviews from '../data/reviews.js';
import va_reviews from '../data/va_reviews.js';
import CardComponent from '../components/CardComponent.js';

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

const Reviewscreen = () => {
  return (
    <>
      <motion.div
        variants={containerVariants}
        initial='hidden'
        animate='visible'
        className='review_container'
      >
        <div className='review_section'></div>
        <div className='create_review'>
          <h1>Our Reviews</h1>
          <Link to='/create_review'>
            <Button className='review-button'>Leave a Review</Button>
          </Link>
        </div>

        <Container>
          {/* <div className='tabs_container'>
            <Tabs
              defaultActiveKey='client'
              id='uncontrolled-tab-example'
              className='mb-3'
              justify
            >
              <Tab eventKey='client' title='Client'>
                {reviews.map((review) => (
                  <div key={review.id}>
                    <CardComponent review={review} />
                  </div>
                ))}
              </Tab>
              <Tab eventKey='va' title='Virtual Assistant'>
                {va_reviews.map((review) => (
                  <div key={review.id}>
                    <CardComponent review={review} />
                  </div>
                ))}
              </Tab>
            </Tabs>
          </div> */}

          <div>
            {reviews.map((review) => (
              <div key={review.id}>
                <CardComponent review={review} />
              </div>
            ))}
          </div>
        </Container>
      </motion.div>
    </>
  );
};

export default Reviewscreen;
