import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Accordionscreen = () => {
  return (
    <>
      <div className='accordion-container'>
        <div className='accordion-text'>
          <h1 className='text-center'>SUPPORT OWL'S VIRTUAL ASSISTANTS</h1>

          <Container>
            <div className='text-center'>
              <p>
                Our Virtual Assistants are professionals with verified and
                confirmed experiences in their respective fields. Each
                individual strives for the ideal characteristics to be the best
                Virtual Assistant for their clients.
              </p>
              <p>
                Support Owl's Virtual Assistants are{' '}
                <strong>Independent, Precise, Reliable, Responsible</strong> and
                have great <strong>Work Ethics</strong>. They are ready to
                perform tasks that can be done remotely in a timely manner for a
                fraction of the expense you would pay in a traditional office
                setting.
              </p>
            </div>
            <LinkContainer to='/contact'>
              <Button className='accordion-button'>Start Hiring Now!</Button>
            </LinkContainer>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Accordionscreen;
