import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Container, Card } from 'react-bootstrap';

const Paymentscreen = () => {
  return (
    <>
      <div className='payment-page'>
        <div className='payment-page-header'>
          <h1>Payment TERMS AND CONDITIONS</h1>
          <h3>PayPal Payment Process</h3>
        </div>

        <Container>
          <Card className='payment-policy-card'>
            <Card.Body>
              <Card.Header>
                <h4 className='card-payment-header'>Online Payment Policy</h4>
              </Card.Header>
              <Card.Text>
                <h2 className='payment-card-para'>
                  By using the online payment system, you agree to the
                  following:
                </h2>
                <ul>
                  <li>
                    The information provided is true and correct to the best of
                    your knowledge.
                  </li>
                  <li>
                    You understand that Support Owl PH reserves the right to
                    change/modify the fees’ relating to the payments from time
                    to time and you agree to abide by the same.
                  </li>
                  <li>
                    Support Owl PH has the right in granting or rejecting
                    payments.
                  </li>
                  <li>
                    You authorize Support Owl PH to charge your Credit
                    Card/Debit Card/ Bank account towards the fee as per the
                    authorization given by you in the Online Application Form.
                  </li>
                  <li>
                    Support Owl PH will not be responsible for any damage
                    suffered by you from use of the services on this site.
                  </li>
                </ul>
                <h3>Fee Refund Policy</h3>
                <p>
                  After proceeding with the payment process, if the client wants
                  to withdraw himself/herself from the admission procedure the
                  following charges will be forfeited if already paid
                </p>
                <p>-Application Fee</p>
                <p>No refund will be granted once the payment is done.</p>
                <p>
                  Duplicate Clause – If client paid twice for one payment than
                  transaction amount will be paid within 15 to 20 working days
                  via same source.
                </p>

                <p>
                  Payment is due at the specific date that the Virtual Assistant
                  has first rendered service.
                </p>
              </Card.Text>
              <Card.Text className='payment-paypal'>
                <h3>Payment Process via PayPal</h3>
                <p className='payment-continue'>
                  If you wish to proceed w/ the payment click on the link below
                </p>
                <PayPalScriptProvider
                  options={{
                    'client-id':
                      'AZsoe6kQCbwfsjbyGea5FWWnk6lwFau3-29w6vz18F09kJ9yMm4r6y3liTQ-PdxnISRzhIOzR4NBWt1Q',
                  }}
                >
                  <PayPalButtons
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              value: '1.01',
                            },
                          },
                        ],
                      });
                    }}
                    onApprove={async (data, actions) => {
                      const details = await actions.order.capture();
                      const name = details.payer.name.given_name;
                      alert('Thank you for your payment ' + name);
                    }}
                  />
                </PayPalScriptProvider>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Paymentscreen;
