import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Heroscreen = () => {
  return (
    <>
      <div className="hero_container">
        <section className="hero-body">
          <Row>
            <Col md={6} sx={12}></Col>
            <Col md={6} sx={12}>
              {" "}
              <div className="hero-text">
                <motion.p
                  className="hero-text-header-p"
                  initial={{ y: "-10vh" }}
                  animate={{ y: 0 }}
                  transition={{ duration: 1.2 }}
                >
                  ACCELERATE YOUR BUSINESS WITHOUT BREAKING THE BANK.
                </motion.p>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 3 }}
                >
                  <h1>SUPPORT OWL</h1>

                  <p className="hero-text-p">
                    GENERAL | ADMINISTRATIVE | MARKETING SPECIALIST
                  </p>
                  <p className="hero-text-p">
                    REAL ESTATE | TRANSACTION COORDINATOR | AND MORE!
                  </p>
                </motion.div>

                <LinkContainer to="/services">
                  <motion.div
                    className="button-container"
                    initial={{ y: "90vh" }}
                    animate={{ y: 0 }}
                    transition={{ duration: 1.2 }}
                  >
                    <p>HELP HAS ARRIVED!</p>
                    <Link to="/contact">
                      <Button className="hero-button">
                        HIRE THE PERFECT VIRTUAL ASSISTANT
                      </Button>
                    </Link>
                  </motion.div>
                </LinkContainer>
              </div>
            </Col>
          </Row>
        </section>

        <div className="card_header_section2">
          <Container>
            <Row>
              <Col md={5} sx={12} className="card_left_section">
                <h1>Help is only a virtual assistant away</h1>
                <p>
                  We help clients build, create and maintain leverage for both
                  small and big businesses with the help of our amazing
                  outsourced talents.
                </p>
              </Col>

              <Col md={7} sx={12} className="card_section_icons">
                <Row>
                  <Col md={6} xs={12}>
                    <h4>
                      {" "}
                      <i className="fas fa-eye card_icon eye"></i>Focus on
                      business
                    </h4>
                    <p>
                      Having an absolute laser-focus on your very specific goals
                    </p>
                  </Col>
                  <Col md={6} xs={12}>
                    <h4>
                      {" "}
                      <i className="fas fa-tasks card_icon task"></i>Lighten
                      workload
                    </h4>
                    <p>Delegate tasks and focus on the business growth</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <h4>
                      <i className="fas fa-wallet card_icon wallet"></i>Save
                      money
                    </h4>
                    <p>Fully Remote. No office space or equipments needed</p>
                  </Col>
                  <Col md={6} xs={12}>
                    <h4>
                      {" "}
                      <i className="fas fa-chart-line card_icon chart"></i>
                      Meet Target
                    </h4>
                    <p>Daily, weekly, monthly targets acquired with ease</p>
                    <p></p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Heroscreen;
