import React from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Container,
  Modal,
  ListGroup,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";

// function Modal1(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton className="bg-primary">
//         <Modal.Title id="contained-modal-title-vcenter" className="text-light">
//           <h1 className="va_modal_header">GENERAL VIRTUAL ASSISTANT</h1>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-primary">
//         <div className="p-3">
//           <ul className="va_modal_ul">
//             <li>Email management and handle phone calls</li>
//             <li>Book travel and accommodations</li>
//             <li>Prepare customer spreadsheets</li>
//             <li>Perform market research</li>
//             <li>Create feature presentations</li>
//             <li>Handle employee administrative queries</li>
//             <li>Provide customer service</li>
//             <li>Data entry and database management</li>
//             <li>Scheduling and managing appointments and calendars</li>
//             <li>Arrange meetings and sending of invitations to team members</li>
//           </ul>
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="bg-primary">
//         <Button className="modal_button" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function Modal2(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton className="bg-primary">
//         <Modal.Title id="contained-modal-title-vcenter" className="text-light">
//           <h1 className="va_modal_header">MEDICAL VIRTUAL ASSISTANT</h1>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-primary">
//         <div className="p-3">
//           <ul className="va_modal_ul">
//             <h3 className="list-header">ADMIN VIRTUAL ASSISTANT</h3>
//             <li>Intake of new patients and referrals details</li>
//             <li>Appointment setting</li>
//             <li>Calendar management</li>
//             <li>Organizing files in your database</li>
//             <li>Supply management </li>
//             <li>Medical billing and coding</li>
//             <li>Follow-ups and checking on patients after appointments</li>
//             <li>Prescription refills</li>
//             <li>Email management</li>
//             <li>Transferring physical forms into a digital format</li>
//             <h3 className="mt-3 list-header">MEDICAL SCRIBE/TRANSCRIBER</h3>
//             <li>Chart examinations in real time or via recording</li>
//             <h3 className="mt-3 list-header">RECEPTIONIST</h3>
//             <li>
//               Take in-bound calls and messages, transfer urgent calls as needed
//               Handle all communication via email, text, live chat and phone
//               Calendar management
//             </li>
//           </ul>
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="bg-primary">
//         <Button className="modal_button" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function Modal3(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton className="bg-primary">
//         <Modal.Title id="contained-modal-title-vcenter" className="text-light">
//           <h1 className="va_modal_header">SOCIAL MEDIA MANAGER</h1>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-primary">
//         <div className="p-3">
//           <ul className="va_modal_ul">
//             <li>Assisting the marketing team with project organization</li>
//             <li>
//               Performing administrative tasks related to marketing activities
//             </li>
//             <li>Conducting marketing research and analyzing obtained data</li>
//             <li>Integration of marketing analytics to an existing project</li>
//             <li>Update database, inventory, and spreadsheets</li>
//             <li>Preparation of promotional presentations and events</li>
//             <li>Updating content for the company's social media and website</li>
//           </ul>
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="bg-primary">
//         <Button className="modal_button" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function Modal4(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton className="bg-primary">
//         <Modal.Title id="contained-modal-title-vcenter" className="text-light">
//           <h1 className="va_modal_header">REAL ESTATE INSIDE SALES AGENT</h1>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-primary">
//         <div className="p-3">
//           <ul className="va_modal_ul">
//             <li>
//               Communicating with leads, making an outbound call to potential
//               customers, and follow-ups
//             </li>
//             <li>Researching and qualifying old and new leads</li>
//             <li>Upselling products and services</li>
//             <li>Closing of sales/deals</li>
//             <li>Researching about competing products and services</li>
//             <li>
//               Responsible for demonstrating key features of products and
//               services
//             </li>
//             <li>
//               Understanding customers' specific needs and providing options for
//               sales opportunities
//             </li>
//             <li>
//               Answering potential leads' inquiries and sending additional
//               options via email
//             </li>
//             <li>Responsible for product and service updates</li>
//             <li>Creating a lead database of current and potential leads</li>
//           </ul>
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="bg-primary">
//         <Button className="modal_button" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function Modal5(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton className="bg-primary">
//         <Modal.Title id="contained-modal-title-vcenter" className="text-light">
//           <h1 className="va_modal_header">ACCOUNTING VIRTUAL ASSISTANT</h1>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-primary">
//         <div className="p-3">
//           <ul className="va_modal_ul">
//             <li>Compute, classify and record financial documents</li>
//             <li>Check accuracy of document figures before finalizing</li>
//             <li>Manage all entries and updates of all transactions</li>
//             <li>Paying/updating company bills</li>
//             <li>Create/prepare general ledgers and invoices</li>
//             <li>Organize financial statements</li>
//             <li>Manage employee reimbursements</li>
//             <li>Tax Management</li>
//           </ul>

//           <h3 className="list-header">MANAGE RECONCILE STATEMENTS SUCH AS:</h3>

//           <ul className="va_modal_ul">
//             <li>Credit cards</li>
//             <li>Vendor's and client's statements</li>
//             <li>Bank account statements</li>
//             <li>Process accounts payable and receivable transactions</li>
//           </ul>

//           <h3 className="list-header">PREPARE FINANCIAL STATEMENTS SUCH AS:</h3>
//           <ul className="va_modal_ul">
//             <li>Trial balance</li>
//             <li>Profit and loss account</li>
//             <li>Balance sheet</li>
//           </ul>
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="bg-primary">
//         <Button className="modal_button" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function Modal6(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton className="bg-primary">
//         <Modal.Title id="contained-modal-title-vcenter" className="text-light">
//           <h1 className="va_modal_header">CUSTOMER SUPPORT/SERVICE</h1>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-primary">
//         <div className="p-3">
//           <ul className="va_modal_ul">
//             <li>
//               Responding to customers' queries via phone or other means of
//               communication
//             </li>
//             <li>
//               Returning phone calls on clients' behalf, with or without guided
//               scripts
//             </li>
//             <li>Checking and responding to voicemail</li>
//             <li>Proofreading and editing email responses.</li>
//             <li>Organizing and filtering customers' emails</li>
//             <li>
//               Engaging with customers and prospects on social media platforms by
//               responding to comments and messages.
//             </li>
//             <li>Setting up support desk via call or chat</li>
//             <li>
//               Guiding customers through the sales process via call, chat, or
//               email.
//             </li>
//             <li>
//               Identifying opportunities for upselling and cross-selling with
//               customers.
//             </li>
//             <li>
//               Building and nurturing strong relationships with long/short term
//               customers or clients
//             </li>
//             <li>Keeping track of important dates and schedules</li>
//             <li>Tactfully handling customer complaints and concerns</li>
//             <li>
//               Resolving escalated cases and forwarding them to the next person
//               in charge
//             </li>
//             <li>
//               Keeping records of customer details needed for sales opportunities
//             </li>
//           </ul>
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="bg-primary">
//         <Button className="modal_button" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function Modal7(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton className="bg-primary">
//         <Modal.Title id="contained-modal-title-vcenter" className="text-light">
//           <h1 className="va_modal_header">REAL ESTATE VIRTUAL ASSISTANT</h1>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-primary">
//         <div className="p-3">
//           <ul className="va_modal_ul">
//             <h3 className="list-header">LISTING COORDINATOR</h3>
//             <li>Manage all aspects of sellers transactions</li>
//             <li>
//               Prepares all listing materials: pre-listing presentation, listing
//               agreement, sellers' disclosure, etc
//             </li>
//             <li>
//               Provide proactive weekly feedback to sellers regarding all
//               marketing activities
//             </li>
//             <li>
//               Coordinate with all public open houses and open broker houses
//             </li>
//             <li>Update all listing information into the marketing websites</li>
//             <li>
//               Submit all necessary documents to the office broker as needed
//             </li>
//             <li>
//               Input/update all necessary information into client database and
//               transaction management systems
//             </li>
//             <h3 className="mt-3 list-header">TRANSACTION COORDINATOR</h3>
//             <li>
//               Manage all aspects of buyer and seller transactions from purchase
//               agreement to closure
//             </li>
//             <li>Coordinate inspections and assist in negotiations</li>
//             <li>
//               Regular update and maintain communication with clients, agents,
//               title officers, and lenders
//             </li>
//             <li>
//               Provide all necessary documents to office broker for file
//               compliance
//             </li>
//             <li>Input/update client database</li>
//             <li>Schedule and attend the closing process</li>
//           </ul>
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="bg-primary">
//         <Button className="modal_button" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function Modal9(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton className="bg-primary">
//         <Modal.Title id="contained-modal-title-vcenter" className="text-light">
//           <h1 className="va_modal_header">
//             PROPERTY MANAGEMENT VIRTUAL ASSISTANT
//           </h1>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-primary">
//         <div className="p-3">
//           <ul className="va_modal_ul">
//             <h3 className="list-header">MAINTENANCE COORDINATOR</h3>
//             <li>
//               Conduct business in a manner that attracts and retains residents
//             </li>
//             <li>
//               Guide tenants to perform basic trouble shooting before assigning
//               to vendors
//             </li>
//             <li>
//               Receives, distributes, processes and tracks work orders for
//               maintenance and repair of leased properties
//             </li>
//             <li>
//               Coordinate repair updates and schedules with Tenants, Vendors and
//               Owners
//             </li>
//             <li>
//               Keep detailed records of all related charges for rent deductions,
//               as required
//             </li>
//             <li>
//               Responsible for vendor and tenant follow-up, call-backs, resolving
//               and rescheduling pending work orders, when appropriate
//             </li>

//             <h3 className="mt-3 list-header">PROPERTY ASSISTANT MANAGER</h3>
//             <li>Coordinates with all parties involved</li>
//             <li>Review and Prepare lease documents</li>
//             <li>Perform tenant background checks</li>
//             <li>Schedule move in/out dates</li>
//             <li>
//               Computes, Coordinates and applies per diem charges based on lease
//               agreement
//             </li>
//             <li>Track records of all related charges for rent deductions</li>
//             <li>Keep accurate and details reports of property/tenant issues</li>
//             <li>
//               Diplomatically handle and diffuse sensitive, difficult and
//               disruptive situations that may arise
//             </li>
//           </ul>
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="bg-primary">
//         <Button className="modal_button" onClick={props.onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

const Cardscreen = () => {
  // const [modalShow, setModalShow] = React.useState(false);
  // const [modalShow2, setModalShow2] = React.useState(false);
  // const [modalShow3, setModalShow3] = React.useState(false);
  // const [modalShow4, setModalShow4] = React.useState(false);
  // const [modalShow5, setModalShow5] = React.useState(false);
  // const [modalShow6, setModalShow6] = React.useState(false);
  // const [modalShow7, setModalShow7] = React.useState(false);
  // const [modalShow8, setModalShow8] = React.useState(false);
  // const [modalShow9, setModalShow9] = React.useState(false);
  return (
    <>
      <Container>
        <div className="card-section">
          <h1 className="text-center">VIRTUAL ASSISTANT SERVICES</h1>
          <h4>
            Choose from our wide variety of VA's to accomplish your tasks.
          </h4>

          <Row>
            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box1">
                <Card.Header>REAL ESTATE VIRTUAL ASSISTANTS</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Listing Coordinator</li>
                      <li>Transaction Coordinator</li>
                      <li>Administrative Assistant</li>
                      <li>Real Estate Inside Sales Agent</li>
                      <li>Social Media Manager</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkContainer to="/realestate">
                    <Button
                      className="modal_button btn-lg
                      btn-block"
                    >
                      View Details
                    </Button>
                  </LinkContainer>
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box2">
                <Card.Header>
                  PROPERTY MANAGEMENT VIRTUAL ASSISTANTS
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Property Assistant Manager</li>
                      <li>Maintenance Coordinator</li>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkContainer to="/propertymanagement">
                    <Button
                      className="modal_button btn-lg
                      btn-block"
                    >
                      View Details
                    </Button>
                  </LinkContainer>
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box3">
                <Card.Header>MEDICAL VIRTUAL ASSISTANTS</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Medical Admin Virtual Assistant</li>
                      <li>Medical Scribe/Transcriber</li>
                      <li>Receptionist</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkContainer to="/medical">
                    <Button
                      className="modal_button btn-lg
                      btn-block"
                    >
                      View Details
                    </Button>
                  </LinkContainer>
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box4">
                <Card.Header>OTHER VIRTUAL ASSISTANT ROLES</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>General Virtual Assistant</li>
                      <li>Customer Service Representative</li>
                      <li>Accounting Virtual Assistant</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <LinkContainer to="/administrative">
                    <Button
                      className="modal_button btn-lg
                      btn-block"
                    >
                      View Details
                    </Button>
                  </LinkContainer>
                </Card.Footer>
              </Card>
            </Col>
          </Row>

          {/* <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box6">
                <Card.Header>REAL ESTATE INSIDE SALES AGENT</Card.Header>
                <Card.Body>
                  <Card.Text>
                
                    <ul>
                      <li>
                        Communicating with leads, making an outbound call to
                        potential customers, and follow-ups
                      </li>
                      <li>Researching and qualifying old and new leads</li>
                      <li>Upselling products and services</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow4(true)}
                  >
                    View Details
                  </Button>
                  <Modal4
                    show={modalShow4}
                    onHide={() => setModalShow4(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box3">
                <Card.Header>MEDICAL VIRTUAL ASSISTANT</Card.Header>
                <Card.Body>
                  <Card.Text>
                 
                    <ul>
                      <li>
                        Take in-bound calls and messages, transfer urgent calls
                        ,handle all communication via email, text, live chat and
                        phone
                      </li>
                      <li>Intake of new patients and referrals details</li>
                      <li>Chart examinations in real time or via recording</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow2(true)}
                  >
                    View Details
                  </Button>
                  <Modal2
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box1">
                <Card.Header>GENERAL VIRTUAL ASSISTANT</Card.Header>
                <Card.Body>
                  <Card.Text>
             
                    <ul>
                      <li>
                        Arrange meetings and sending of invitations to team
                        members
                      </li>
                      <li>Email management and handle phone calls</li>
                      <li>
                        Scheduling and managing appointments and calendars
                      </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow(true)}
                  >
                    View Details
                  </Button>
                  <Modal1 show={modalShow} onHide={() => setModalShow(false)} />
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box4">
                <Card.Header>SOCIAL MEDIA MANAGER</Card.Header>
                <Card.Body>
                  <Card.Text>
                 
                    <ul>
                      <li>
                        Assisting the marketing team with project organization
                      </li>
                      <li>Update database, inventory, and spreadsheets</li>
                      <li>
                        Updating content for the company's social media and
                        website
                      </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow3(true)}
                  >
                    View Details
                  </Button>
                  <Modal3
                    show={modalShow3}
                    onHide={() => setModalShow3(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box5">
                <Card.Header>ACCOUNTING VIRTUAL ASSISTANT</Card.Header>
                <Card.Body>
                  <Card.Text>
                  
                    <ul>
                      <li>Compute, classify and record financial documents</li>
                      <li>
                        Check accuracy of document figures before finalizing
                      </li>
                      <li>Create/prepare general ledgers and invoices</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow5(true)}
                  >
                    View Details
                  </Button>
                  <Modal5
                    show={modalShow5}
                    onHide={() => setModalShow5(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box2">
                <Card.Header>CUSTOMER SUPPORT/SERVICE</Card.Header>
                <Card.Body>
                  <Card.Text>
                 
                    <ul>
                      <li>Setting up support desk via call or chat</li>
                      <li>Checking and responding to voicemail</li>
                      <li>Proofreading and editing email responses.</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow6(true)}
                  >
                    View Details
                  </Button>
                  <Modal6
                    show={modalShow6}
                    onHide={() => setModalShow6(false)}
                  />
                </Card.Footer>
              </Card>
            </Col> */}

          {/* <h1>Real Estate Services</h1>
          <Row>
            <Col md={4} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box7">
                <Card.Header>REAL ESTATE VIRTUAL ASSISTANT</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>
                        Manage all aspects of buyer and seller transactions from
                        purchase agreement to closure
                      </li>
                      <li>
                        Prepares all listing materials: pre-listing
                        presentation, listing agreement, sellers' disclosure etc
                      </li>
                      <li>Schedule and attend the closing process</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow7(true)}
                  >
                    View Details
                  </Button>
                  <Modal7
                    show={modalShow7}
                    onHide={() => setModalShow7(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box6">
                <Card.Header>REAL ESTATE INSIDE SALES AGENT</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>
                        Communicating with leads, making an outbound call to
                        potential customers, and follow-ups
                      </li>
                      <li>Researching and qualifying old and new leads</li>
                      <li>Upselling products and services</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow4(true)}
                  >
                    View Details
                  </Button>
                  <Modal4
                    show={modalShow4}
                    onHide={() => setModalShow4(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box8">
                <Card.Header>PROPERTY MANAGEMENT VIRTUAL ASSISTANT</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>
                        Conduct business in a manner that attracts and retains
                        residents
                      </li>
                      <li>
                        Receives, distributes, processes and tracks work orders
                        for maintenance and repair of leased properties
                      </li>
                      <li>Coordinates with all parties involved</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow9(true)}
                  >
                    View Details
                  </Button>
                  <Modal9
                    show={modalShow9}
                    onHide={() => setModalShow9(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>
          </Row>

          <h1>Administrative Services</h1>
          <Row>
            <Col md={4} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box2">
                <Card.Header>CUSTOMER SUPPORT/SERVICE</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Setting up support desk via call or chat</li>
                      <li>Checking and responding to voicemail</li>
                      <li>Proofreading and editing email responses.</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow6(true)}
                  >
                    View Details
                  </Button>
                  <Modal6
                    show={modalShow6}
                    onHide={() => setModalShow6(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box2">
                <Card.Header>CUSTOMER SUPPORT/SERVICE</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Setting up support desk via call or chat</li>
                      <li>Checking and responding to voicemail</li>
                      <li>Proofreading and editing email responses.</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow6(true)}
                  >
                    View Details
                  </Button>
                  <Modal6
                    show={modalShow6}
                    onHide={() => setModalShow6(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box2">
                <Card.Header>CUSTOMER SUPPORT/SERVICE</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Setting up support desk via call or chat</li>
                      <li>Checking and responding to voicemail</li>
                      <li>Proofreading and editing email responses.</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow6(true)}
                  >
                    View Details
                  </Button>
                  <Modal6
                    show={modalShow6}
                    onHide={() => setModalShow6(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box2">
                <Card.Header>CUSTOMER SUPPORT/SERVICE</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Setting up support desk via call or chat</li>
                      <li>Checking and responding to voicemail</li>
                      <li>Proofreading and editing email responses.</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow6(true)}
                  >
                    View Details
                  </Button>
                  <Modal6
                    show={modalShow6}
                    onHide={() => setModalShow6(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Card className="text-center card_section2 va_box2">
                <Card.Header>CUSTOMER SUPPORT/SERVICE</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Setting up support desk via call or chat</li>
                      <li>Checking and responding to voicemail</li>
                      <li>Proofreading and editing email responses.</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  {" "}
                  <Button
                    className="modal_button btn-lg
                      btn-block"
                    onClick={() => setModalShow6(true)}
                  >
                    View Details
                  </Button>
                  <Modal6
                    show={modalShow6}
                    onHide={() => setModalShow6(false)}
                  />
                </Card.Footer>
              </Card>
            </Col>
          </Row> */}

          {/* <Row>
          <Col md={3} className="card_container3">
            <i className="fas fa-building card_icon building"></i>

            <h2>Real Estate Virtual Assistant</h2>
            <ul>
              <li>Listing Coordinator</li>
              <li>Transaction Coordinator</li>
              <li>Administrative Assistant</li>
              <li>Real Estate Inside Sales Agent</li>
              <li>Social Media Manager</li>
            </ul>
          </Col>
          <Col md={3} className="card_container3">
            <i className="fas fa-city card_icon buildings"></i>
            <h2>Property Management Virtual Assistant</h2>
            <ul>
              <li>Property Assistant Manager</li>
              <li>Maintenance Coordinator</li>
            </ul>
          </Col>
          <Col md={3} className="card_container3">
            <i className="fas fa-notes-medical card_icon medical"></i>
            <h2>Medical Virtual Assistant</h2>
            <ul>
              <li>Medical Admin Virtual Assistant</li>
              <li>Medical Scribe/Transcriber</li>
              <li>Receptionist</li>
            </ul>
          </Col>
          <Col md={3} className="card_container3">
            <i className="fas fa-list card_icon list"></i>
            <h2>Other Virtual Assistant Roles</h2>
            <ul>
              <li>General Virtual Assistant</li>
              <li>Customer Service Representative</li>
              <li>Accounting Virtual Assistant</li>
            </ul>
          </Col>
        </Row> */}
        </div>
      </Container>
    </>
  );
};

export default Cardscreen;
