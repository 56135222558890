import React from 'react';
import { Container, Card, Button } from 'react-bootstrap';

const handleClick = () => {
  window.open('https://buy.stripe.com/test_3cs7w6aGdaazg3S4gh');
};

const Paymentscreen2 = () => {
  return (
    <>
      <div className='payment-page'>
        <div className='payment-page-header'>
          <h1>Payment TERMS AND CONDITIONS</h1>
          <h3>Stripe Payment Process</h3>
        </div>

        <Container>
          <Card className='payment-policy-card'>
            <Card.Body>
              <Card.Header>
                <h4 className='card-payment-header'>Online Payment Policy</h4>
              </Card.Header>
              <Card.Text>
                <h2 className='payment-card-para'>
                  By using the online payment system, you agree to the
                  following:
                </h2>
                <ul>
                  <li>
                    The information provided is true and correct to the best of
                    your knowledge.
                  </li>
                  <li>
                    You understand that Support Owl PH reserves the right to
                    change/modify the fees’ relating to the payments from time
                    to time and you agree to abide by the same.
                  </li>
                  <li>
                    Support Owl PH has the right in granting or rejecting
                    payments.
                  </li>
                  <li>
                    You authorize Support Owl PH to charge your Credit
                    Card/Debit Card/ Bank account towards the fee as per the
                    authorization given by you in the Online Application Form.
                  </li>
                  <li>
                    Support Owl PH will not be responsible for any damage
                    suffered by you from use of the services on this site.
                  </li>
                </ul>
                <h3>Fee Refund Policy</h3>
                <p>
                  After proceeding with the payment process, if the client wants
                  to withdraw himself/herself from the admission procedure the
                  following charges will be forfeited if already paid
                </p>
                <p>-Application Fee</p>
                <p>No refund will be granted once the payment is done.</p>
                <p>
                  Duplicate Clause – If client paid twice for one payment than
                  transaction amount will be paid within 15 to 20 working days
                  via same source.
                </p>

                <p>
                  Payment is due at the specific date that the Virtual Assistant
                  has first rendered service.
                </p>
              </Card.Text>
              <Card.Text className='payment-stripe'>
                <h3>Payment Process via Stripe</h3>
                <p className='payment-continue'>
                  If you wish to proceed w/ the payment click on the link below
                </p>

                <div className='powered_container'>
                  <p> Powered by: </p>
                  <i
                    class='fab fa-cc-stripe'
                    style={{ marginLeft: '.5rem' }}
                  ></i>
                </div>
                <Button className='payment-button' onClick={handleClick}>
                  Stripe Check out Payment
                </Button>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Paymentscreen2;
