import React from "react";
import { PopupWidget } from "react-calendly";

import Cardscreen from "../screens/Cardscreen";
import Heroscreen from "../screens/Heroscreen";
import Stepscreen from "../screens/Stepscreen";
import Accordionscreen from "../screens/Accordionscreen";

import { motion } from "framer-motion";
import Carouselscreen from "./Carouselscreen";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

const Homescreen = () => {
  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <PopupWidget
          url="https://calendly.com/supportowlph/virtual-assistant-consultation?month=2023-05"
          rootElement={document.getElementById("root")}
          text="Set Appointment"
          textColor="#fff"
          color="#178B86"
        />

        <Heroscreen />

        <Carouselscreen />

        <Cardscreen />

        <Accordionscreen />

        <Stepscreen />
      </motion.div>
    </>
  );
};

export default Homescreen;
