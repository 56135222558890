import React from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";

import service5 from "../assets/services/service5.jpg";
import service6 from "../assets/services/service6.jpg";

import { motion } from "framer-motion";

import { PopupWidget } from "react-calendly";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

function Modal9(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">MAINTENANCE COORDINATOR</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul ul className="va_modal_ul">
            <li>
              Conduct business in a manner that attracts and retains residents
            </li>
            <li>
              Guide tenants to perform basic trouble shooting before assigning
              to vendors
            </li>
            <li>
              Receives, distributes, processes and tracks work orders for
              maintenance and repair of leased properties
            </li>
            <li>
              Coordinate repair updates and schedules with Tenants, Vendors and
              Owners
            </li>
            <li>
              Keep detailed records of all related charges for rent deductions,
              as required
            </li>
            <li>
              Responsible for vendor and tenant follow-up, call-backs, resolving
              and rescheduling pending work orders, when appropriate
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modal10(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">PROPERTY ASSISTANT MANAGER</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul ul className="va_modal_ul">
            <li>Coordinates with all parties involved</li>
            <li>Review and Prepare lease documents</li>
            <li>Perform tenant background checks</li>
            <li>Schedule move in/out dates</li>
            <li>
              Computes, Coordinates and applies per diem charges based on lease
              agreement
            </li>
            <li>Track records of all related charges for rent deductions</li>
            <li>Keep accurate and details reports of property/tenant issues</li>
            <li>
              Diplomatically handle and diffuse sensitive, difficult and
              disruptive situations that may arise
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ServicesPropertyManagement = () => {
  const [modalShow9, setModalShow9] = React.useState(false);
  const [modalShow10, setModalShow10] = React.useState(false);

  return (
    <>
      <Container>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="services_main_container"
        >
          <PopupWidget
            url="https://calendly.com/supportowlph/virtual-assistant-consultation?month=2023-05"
            rootElement={document.getElementById("root")}
            text="Set Appointment"
            textColor="#fff"
            color="#178B86"
          />
          <h1>Property Management Virtual Assistants</h1>
          <div className="admin_desktop">
            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service5}
                  alt="eight slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>PROPERTY ASSISTANT MANAGER</h2>
                <ul>
                  <li>Coordinates with all parties involved</li>
                  <li>Review and Prepare lease documents</li>
                  <li>
                    Computes, Coordinates and applies per diem charges based on
                    lease agreement
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow10(true)}
                >
                  View Details
                </Button>
                <Modal10
                  show={modalShow10}
                  onHide={() => setModalShow10(false)}
                />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12} className="services_text">
                <h2>MAINTENANCE COORDINATOR</h2>
                <ul>
                  <li>
                    Conduct business in a manner that attracts and retains
                    residents
                  </li>
                  <li>
                    Guide tenants to perform basic trouble shooting before
                    assigning to vendors
                  </li>
                  <li>
                    Receives, distributes, processes and tracks work orders for
                    maintenance and repair of leased properties
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow9(true)}
                >
                  View Details
                </Button>
                <Modal9 show={modalShow9} onHide={() => setModalShow9(false)} />
              </Col>
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service6}
                  alt="eight slide"
                />
              </Col>
            </Row>
          </div>

          <div className="admin_mobile">
            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service5}
                  alt="eight slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>PROPERTY ASSISTANT MANAGER</h2>
                <ul>
                  <li>Coordinates with all parties involved</li>
                  <li>Review and Prepare lease documents</li>
                  <li>
                    Computes, Coordinates and applies per diem charges based on
                    lease agreement
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow10(true)}
                >
                  View Details
                </Button>
                <Modal10
                  show={modalShow10}
                  onHide={() => setModalShow10(false)}
                />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service6}
                  alt="eight slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>MAINTENANCE COORDINATOR</h2>
                <ul>
                  <li>
                    Conduct business in a manner that attracts and retains
                    residents
                  </li>
                  <li>
                    Guide tenants to perform basic trouble shooting before
                    assigning to vendors
                  </li>
                  <li>
                    Receives, distributes, processes and tracks work orders for
                    maintenance and repair of leased properties
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow9(true)}
                >
                  View Details
                </Button>
                <Modal9 show={modalShow9} onHide={() => setModalShow9(false)} />
              </Col>
            </Row>
          </div>
        </motion.div>
      </Container>
    </>
  );
};

export default ServicesPropertyManagement;
