import React from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const ThankyouReviewScreen = () => {
  return (
    <>
      <div className='text-center thankyou-page pt-5'>
        <h1>" Thank You "</h1>
        <h2>We appreciate your time.</h2>
        <p>Your reviews / testimonials are important to improve our service.</p>
        <LinkContainer to='/'>
          <Button className='thankyou-btn'>Go To Home Page</Button>
        </LinkContainer>
      </div>
    </>
  );
};

export default ThankyouReviewScreen;
