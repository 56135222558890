import React from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import service10 from "../assets/services/service10.jpg";
import service11 from "../assets/services/service11.jpg";
import service12 from "../assets/services/service12.jpg";

import { motion } from "framer-motion";

import { PopupWidget } from "react-calendly";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

function Modal1(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">GENERAL VIRTUAL ASSISTANT</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul className="va_modal_ul">
            <li>Email management and handle phone calls</li>
            <li>Book travel and accommodations</li>
            <li>Prepare customer spreadsheets</li>
            <li>Perform market research</li>
            <li>Create feature presentations</li>
            <li>Handle employee administrative queries</li>
            <li>Provide customer service</li>
            <li>Data entry and database management</li>
            <li>Scheduling and managing appointments and calendars</li>
            <li>Arrange meetings and sending of invitations to team members</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modal5(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">ACCOUNTING VIRTUAL ASSISTANT</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul className="va_modal_ul">
            <li>Compute, classify and record financial documents</li>
            <li>Check accuracy of document figures before finalizing</li>
            <li>Manage all entries and updates of all transactions</li>
            <li>Paying/updating company bills</li>
            <li>Create/prepare general ledgers and invoices</li>
            <li>Organize financial statements</li>
            <li>Manage employee reimbursements</li>
            <li>Tax Management</li>
          </ul>

          <h3 className="list-header">MANAGE RECONCILE STATEMENTS SUCH AS:</h3>

          <ul className="va_modal_ul">
            <li>Credit cards</li>
            <li>Vendor's and client's statements</li>
            <li>Bank account statements</li>
            <li>Process accounts payable and receivable transactions</li>
          </ul>

          <h3 className="list-header">PREPARE FINANCIAL STATEMENTS SUCH AS:</h3>
          <ul className="va_modal_ul">
            <li>Trial balance</li>
            <li>Profit and loss account</li>
            <li>Balance sheet</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modal6(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">CUSTOMER SERVICE REPRESENTATIVE</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul className="va_modal_ul">
            <li>
              Responding to customers' queries via phone or other means of
              communication
            </li>
            <li>
              Returning phone calls on clients' behalf, with or without guided
              scripts
            </li>
            <li>Checking and responding to voicemail</li>
            <li>Proofreading and editing email responses.</li>
            <li>Organizing and filtering customers' emails</li>
            <li>
              Engaging with customers and prospects on social media platforms by
              responding to comments and messages.
            </li>
            <li>Setting up support desk via call or chat</li>
            <li>
              Guiding customers through the sales process via call, chat, or
              email.
            </li>
            <li>
              Identifying opportunities for upselling and cross-selling with
              customers.
            </li>
            <li>
              Building and nurturing strong relationships with long/short term
              customers or clients
            </li>
            <li>Keeping track of important dates and schedules</li>
            <li>Tactfully handling customer complaints and concerns</li>
            <li>
              Resolving escalated cases and forwarding them to the next person
              in charge
            </li>
            <li>
              Keeping records of customer details needed for sales opportunities
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ServicesAdministrativeScreen = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow5, setModalShow5] = React.useState(false);
  const [modalShow6, setModalShow6] = React.useState(false);

  return (
    <>
      <Container>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="services_main_container"
        >
          <PopupWidget
            url="https://calendly.com/supportowlph/virtual-assistant-consultation?month=2023-05"
            rootElement={document.getElementById("root")}
            text="Set Appointment"
            textColor="#fff"
            color="#178B86"
          />
          <h1>OTHER VIRTUAL ASSISTANT ROLES</h1>
          <div className="admin_desktop">
            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service10}
                  alt="First slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>General Virtual Assistant</h2>
                <ul>
                  <li>Email management and handle phone calls</li>
                  <li>Book travel and accommodations</li>
                  <li>Prepare customer spreadsheets</li>
                  <li>Perform market research</li>
                  <li>Create feature presentations</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow(true)}
                >
                  View Details
                </Button>
                <Modal1 show={modalShow} onHide={() => setModalShow(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12} className="services_text">
                <h2>Customer Service Representative</h2>
                <ul>
                  <li>
                    Responding to customers' queries via phone or other means of
                    communication
                  </li>
                  <li>
                    Returning phone calls on clients' behalf, with or without
                    guided scripts
                  </li>
                  <li>Checking and responding to voicemail</li>
                  <li>Proofreading and editing email responses</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow6(true)}
                >
                  View Details
                </Button>
                <Modal6 show={modalShow6} onHide={() => setModalShow6(false)} />
              </Col>

              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service11}
                  alt="second slide"
                />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service12}
                  alt="fifth slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>Accounting Virtual Assistant</h2>
                <ul>
                  <li>
                    <strong>Manage Reconcile Statements:</strong> Credit Cards,
                    Vendor's & clients statements, Bank account statement etc.
                  </li>
                  <li>
                    <strong>Prepare Financial Statements: </strong> Trial
                    balance, Profit & loss account, Balance sheet etc.
                  </li>
                  <li>Compute, classify and record financial documents</li>
                  <li>Check accuracy of document figures before finalizing</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow5(true)}
                >
                  View Details
                </Button>
                <Modal5 show={modalShow5} onHide={() => setModalShow5(false)} />
              </Col>
            </Row>
          </div>

          <div className="admin_mobile">
            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service10}
                  alt="First slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>General Virtual Assistant</h2>
                <ul>
                  <li>Email management and handle phone calls</li>
                  <li>Book travel and accommodations</li>
                  <li>Prepare customer spreadsheets</li>
                  <li>Perform market research</li>
                  <li>Create feature presentations</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow(true)}
                >
                  View Details
                </Button>
                <Modal1 show={modalShow} onHide={() => setModalShow(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service11}
                  alt="second slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>Customer Support / Service</h2>
                <ul>
                  <li>
                    Responding to customers' queries via phone or other means of
                    communication
                  </li>
                  <li>
                    Returning phone calls on clients' behalf, with or without
                    guided scripts
                  </li>
                  <li>Checking and responding to voicemail</li>
                  <li>Proofreading and editing email responses</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow6(true)}
                >
                  View Details
                </Button>
                <Modal6 show={modalShow6} onHide={() => setModalShow6(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service12}
                  alt="fifth slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>Accounting Virtual Assistant</h2>
                <ul>
                  <li>
                    <strong>Manage Reconcile Statements:</strong> Credit Cards,
                    Vendor's & clients statements, Bank account statement etc.
                  </li>
                  <li>
                    <strong>Prepare Financial Statements: </strong> Trial
                    balance, Profit & loss account, Balance sheet etc.
                  </li>
                  <li>Compute, classify and record financial documents</li>
                  <li>Check accuracy of document figures before finalizing</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow5(true)}
                >
                  View Details
                </Button>
                <Modal5 show={modalShow5} onHide={() => setModalShow5(false)} />
              </Col>
            </Row>
          </div>
        </motion.div>
      </Container>
    </>
  );
};

export default ServicesAdministrativeScreen;
