import React from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import service1 from "../assets/services/service1.jpg";
import service2 from "../assets/services/service2.jpg";
import service3 from "../assets/services/service3.jpg";
import service4 from "../assets/services/service4.jpg";
import service12 from "../assets/services/service13.jpg";

import { motion } from "framer-motion";

import { PopupWidget } from "react-calendly";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

function Modal1(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">ADMINISTRATIVE ASSISTANT</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul className="va_modal_ul">
            <li>Email management and handle phone calls</li>
            <li>Book travel and accommodations</li>
            <li>Prepare customer spreadsheets</li>
            <li>Perform market research</li>
            <li>Create feature presentations</li>
            <li>Handle employee administrative queries</li>
            <li>Provide customer service</li>
            <li>Data entry and database management</li>
            <li>Scheduling and managing appointments and calendars</li>
            <li>Arrange meetings and sending of invitations to team members</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modal3(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">SOCIAL MEDIA MANAGER</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul className="va_modal_ul">
            <li>Assisting the marketing team with project organization</li>
            <li>
              Performing administrative tasks related to marketing activities
            </li>
            <li>Conducting marketing research and analyzing obtained data</li>
            <li>Integration of marketing analytics to an existing project</li>
            <li>Update database, inventory, and spreadsheets</li>
            <li>Preparation of promotional presentations and events</li>
            <li>Updating content for the company's social media and website</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modal4(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">REAL ESTATE INSIDE SALES AGENT</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul ul className="va_modal_ul">
            <li>
              Communicating with leads, making an outbound call to potential
              customers, and follow-ups
            </li>
            <li>Researching and qualifying old and new leads</li>
            <li>Upselling products and services</li>
            <li>Closing of sales/deals</li>
            <li>Researching about competing products and services</li>
            <li>
              Responsible for demonstrating key features of products and
              services
            </li>
            <li>
              Understanding customers' specific needs and providing options for
              sales opportunities
            </li>
            <li>
              Answering potential leads' inquiries and sending additional
              options via email
            </li>
            <li>Responsible for product and service updates</li>
            <li>Creating a lead database of current and potential leads</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modal7(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">LISTING COORDINATOR</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul ul className="va_modal_ul">
            <li>Manage all aspects of sellers transactions</li>
            <li>
              Prepares all listing materials: pre-listing presentation, listing
              agreement, sellers' disclosure, etc
            </li>
            <li>
              Provide proactive weekly feedback to sellers regarding all
              marketing activities
            </li>
            <li>
              Coordinate with all public open houses and open broker houses
            </li>
            <li>Update all listing information into the marketing websites</li>
            <li>
              Submit all necessary documents to the office broker as needed
            </li>
            <li>
              Input/update all necessary information into client database and
              transaction management systems
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Modal8(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title id="contained-modal-title-vcenter" className="text-light">
          <h1 className="va_modal_header">TRANSACTION COORDINATOR</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-primary">
        <div className="p-3">
          <ul ul className="va_modal_ul">
            <li>
              Manage all aspects of buyer and seller transactions from purchase
              agreement to closure
            </li>
            <li>Coordinate inspections and assist in negotiations</li>
            <li>
              Regular update and maintain communication with clients, agents,
              title officers, and lenders
            </li>
            <li>
              Provide all necessary documents to office broker for file
              compliance
            </li>
            <li>Input/update client database</li>
            <li>Schedule and attend the closing process</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-primary">
        <Button className="modal_button" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ServicesRealEstateScreen = () => {
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [modalShow7, setModalShow7] = React.useState(false);
  const [modalShow8, setModalShow8] = React.useState(false);

  return (
    <>
      <Container>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="services_main_container"
        >
          <PopupWidget
            url="https://calendly.com/supportowlph/virtual-assistant-consultation?month=2023-05"
            rootElement={document.getElementById("root")}
            text="Set Appointment"
            textColor="#fff"
            color="#178B86"
          />
          <h1>Real Estate Virtual Assistants</h1>
          <div className="admin_desktop">
            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service3}
                  alt="seventh slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>LISTING COORDINATOR</h2>
                <ul>
                  <li>Manage all aspects of sellers transactions</li>
                  <li>
                    Submit all necessary documents to the office broker as
                    needed
                  </li>
                  <li>
                    Prepares all listing materials: pre-listing presentation,
                    listing agreement, sellers' disclosure, etc
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow7(true)}
                >
                  View Details
                </Button>
                <Modal7 show={modalShow7} onHide={() => setModalShow7(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12} className="services_text">
                <h2>TRANSACTION COORDINATOR</h2>
                <ul>
                  <li>
                    Manage all aspects of buyer and seller transactions from
                    purchase agreement to closure
                  </li>
                  <li>Coordinate inspections and assist in negotiations</li>
                  <li>
                    Regular update and maintain communication with clients,
                    agents, title officers, and lenders
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow8(true)}
                >
                  View Details
                </Button>
                <Modal8 show={modalShow8} onHide={() => setModalShow8(false)} />
              </Col>

              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service2}
                  alt="seventh slide"
                />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service12}
                  alt="twelve slide"
                />
              </Col>

              <Col md={6} xs={12} className="services_text">
                <h2>ADMINISTRATIVE ASSISTANT</h2>
                <ul>
                  <li>Email management and handle phone calls</li>
                  <li>Book travel and accommodations</li>
                  <li>Prepare customer spreadsheets</li>
                  <li>Perform market research</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow1(true)}
                >
                  View Details
                </Button>
                <Modal1 show={modalShow1} onHide={() => setModalShow1(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12} className="services_text">
                <h2>REAL ESTATE INSIDE SALES AGENT</h2>
                <ul>
                  <li>
                    Communicating with leads, making an outbound call to
                    potential customers, and follow-ups
                  </li>
                  <li>Researching and qualifying old and new leads</li>
                  <li>Upselling products and services</li>
                  <li>Closing of sales/deals</li>
                </ul>

                <div>
                  <Button
                    className="modal_button btn-lg"
                    onClick={() => setModalShow4(true)}
                  >
                    View Details
                  </Button>
                  <Modal4
                    show={modalShow4}
                    onHide={() => setModalShow4(false)}
                  />
                </div>
              </Col>

              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service1}
                  alt="sixth slide"
                />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service4}
                  alt="fourth slide"
                />
              </Col>

              <Col md={6} xs={12} className="services_text">
                <h2>SOCIAL MEDIA MANAGER</h2>
                <ul>
                  <li>
                    Assisting the marketing team with project organization
                  </li>
                  <li>
                    Performing administrative tasks related to marketing
                    activities
                  </li>
                  <li>
                    Conducting marketing research and analyzing obtained data
                  </li>
                  <li>
                    Integration of marketing analytics to an existing project
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow3(true)}
                >
                  View Details
                </Button>
                <Modal3 show={modalShow3} onHide={() => setModalShow3(false)} />
              </Col>
            </Row>
          </div>

          <div className="admin_mobile">
            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service3}
                  alt="seventh slide"
                />
              </Col>
              <Col md={6} xs={12} className="services_text">
                <h2>LISTING COORDINATOR</h2>
                <ul>
                  <li>Manage all aspects of sellers transactions</li>
                  <li>
                    Submit all necessary documents to the office broker as
                    needed
                  </li>
                  <li>
                    Prepares all listing materials: pre-listing presentation,
                    listing agreement, sellers' disclosure, etc
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow7(true)}
                >
                  View Details
                </Button>
                <Modal7 show={modalShow7} onHide={() => setModalShow7(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service2}
                  alt="seventh slide"
                />
              </Col>

              <Col md={6} xs={12} className="services_text">
                <h2>TRANSACTION COORDINATOR</h2>
                <ul>
                  <li>
                    Manage all aspects of buyer and seller transactions from
                    purchase agreement to closure
                  </li>
                  <li>Coordinate inspections and assist in negotiations</li>
                  <li>
                    Regular update and maintain communication with clients,
                    agents, title officers, and lenders
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow8(true)}
                >
                  View Details
                </Button>
                <Modal8 show={modalShow8} onHide={() => setModalShow8(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service12}
                  alt="twelve slide"
                />
              </Col>

              <Col md={6} xs={12} className="services_text">
                <h2>ADMINISTRATIVE ASSISTANT</h2>
                <ul>
                  <li>Email management and handle phone calls</li>
                  <li>Book travel and accommodations</li>
                  <li>Prepare customer spreadsheets</li>
                  <li>Perform market research</li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow1(true)}
                >
                  View Details
                </Button>
                <Modal1 show={modalShow1} onHide={() => setModalShow1(false)} />
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service1}
                  alt="sixth slide"
                />
              </Col>

              <Col md={6} xs={12} className="services_text">
                <h2>REAL ESTATE INSIDE SALES AGENT</h2>
                <ul>
                  <li>
                    Communicating with leads, making an outbound call to
                    potential customers, and follow-ups
                  </li>
                  <li>Researching and qualifying old and new leads</li>
                  <li>Upselling products and services</li>
                  <li>Closing of sales/deals</li>
                </ul>

                <div>
                  <Button
                    className="modal_button btn-lg"
                    onClick={() => setModalShow4(true)}
                  >
                    View Details
                  </Button>
                  <Modal4
                    show={modalShow4}
                    onHide={() => setModalShow4(false)}
                  />
                </div>
              </Col>
            </Row>

            <Row className="admin_row_container">
              <Col md={6} xs={12}>
                <img
                  className="d-block w-100 admin_img"
                  src={service4}
                  alt="fourth slide"
                />
              </Col>

              <Col md={6} xs={12} className="services_text">
                <h2>SOCIAL MEDIA MANAGER</h2>
                <ul>
                  <li>
                    Assisting the marketing team with project organization
                  </li>
                  <li>
                    Performing administrative tasks related to marketing
                    activities
                  </li>
                  <li>
                    Conducting marketing research and analyzing obtained data
                  </li>
                  <li>
                    Integration of marketing analytics to an existing project
                  </li>
                </ul>

                <Button
                  className="modal_button btn-lg"
                  onClick={() => setModalShow3(true)}
                >
                  View Details
                </Button>
                <Modal3 show={modalShow3} onHide={() => setModalShow3(false)} />
              </Col>
            </Row>
          </div>
        </motion.div>
      </Container>
    </>
  );
};

export default ServicesRealEstateScreen;
