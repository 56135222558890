import React from "react";
import ContactForm from "../components/ContactForm";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

const ContactScreen = () => {
  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="form-header">
          <Container>
            <div className="contact-page">
              <h1>Interested in hiring a Support Owl VA?</h1>

              <p>Don't Hesitate to set an appointment with us!</p>
            </div>
            <ContactForm />
          </Container>
        </div>
      </motion.div>
    </>
  );
};

export default ContactScreen;
