import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';

const Thankyoupaymentscreen = () => {
  return (
    <>
      <Container>
        <div className='payment-thankyou-page'>
          <Row>
            <Col md={6} className='payment-left'>
              <h1>Thank you for your payment</h1>
              <h2>Payment Service Details</h2>

              <ul>
                <li>Initial Down Payment $750.00</li>
                <li>Final Payment $750.00</li>
              </ul>

              <h4>
                Powered by <i class='fab fa-cc-stripe'></i>
              </h4>
            </Col>

            <Col md={6} className='payment-right'>
              <i class='far fa-check-circle'></i>
              <h1>Thank you for choosing SupportOwlPH</h1>
              <h3>A payment to Stripe will appear on your statement.</h3>
              <h4>Feel free to call us anytime for questions and inquires.</h4>
              <Button>Homepage</Button>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Thankyoupaymentscreen;
