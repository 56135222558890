import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <>
      <Container>
        <div className='page_not_found_container'>
          <h1>Oooops!</h1>
          <h2>404 - Page Not Found</h2>
          <h3>
            Sorry but the page you are looking for is not found. Please make
            sure you have typed the correct URL
          </h3>
          <Link to='/'>
            <Button className='pgf-button'>Go To HomePage</Button>
          </Link>
        </div>
      </Container>
    </>
  );
};

export default PageNotFound;
