import React from "react";

import { Container, Row, Col, Card } from "react-bootstrap";

import { PopupWidget } from "react-calendly";

import Leadership from "../assets/leadership.jpg";
import Expense from "../assets/expense.jpg";
import Educated from "../assets/educated.jpg";

import Interview from "../assets/about-page.jpg";
import Compiled from "../assets/about-us-image.png";

import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 1.5 },
  },
};

const Aboutscreen = () => {
  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <PopupWidget
          url="https://calendly.com/supportowlph/virtual-assistant-consultation?month=2023-05"
          rootElement={document.getElementById("root")}
          text="Set Appointment"
          textColor="#fff"
          color="#178B86"
        />
        <section className="about-container">
          <div className="about-section text-center">
            <h1>What We Do</h1>
            <p>
              Support Owl is a Philippine-based staffing company hiring only
              top-notched, proactive and professional Filipino Virtual
              Assistants. We capture and evaluate our clients needs and vet the
              best Filipino VAs to match or exceed our clients expectations.
            </p>
          </div>
        </section>

        <Container>
          <div className="about-section2">
            <h1>Mission / Vision</h1>

            <Row>
              <Col md={6} className="about-section-left">
                <p>
                  Support Owl creates opportunities to help organize back-office
                  tasks regardless of the field. We target top-notch expertise
                  and proactive virtual assistants to provide the best results
                  and to save clients money. With us, you can focus on running
                  the business which matters the most and less on task or staff
                  management.
                </p>
              </Col>

              <Col md={6} className="about-section-right">
                <Card className="border-0">
                  <Card.Img src={Interview} variant="top" />
                </Card>
              </Col>
            </Row>
          </div>

          <div className="about-section3">
            <h1>Let us do the searching</h1>

            <Row>
              <Col md={6} className="about-section-left3">
                <p>
                  As a virtual assistant staffing agency, Support Owl recognized
                  the opportunity to help both clients and virtual assistants.
                  Due to the high demand for quality virtual assistants and the
                  lack of qualified candidates with the right skills and
                  knowledge, we search for the best talent to fill that gap. By
                  doing this, we can provide our VA's with an opportunity to
                  build a career for themselves while also providing our clients
                  with the expertise they need.
                </p>
              </Col>

              <Col md={6} className="about-section-right3">
                <Card className="border-0">
                  <Card.Img src={Compiled} variant="top" />
                </Card>
              </Col>
            </Row>
          </div>

          <div className="mission">
            <h1>Why hire a Support Owl Virtual Assistant?</h1>
          </div>

          <div className="about-cards-container">
            <Container>
              <Row>
                <Col sm={12} md={6} xl={4}>
                  <Card className="rounded text-center about-cards">
                    <Card.Img src={Educated} variant="top" />
                    <Card.Body>
                      <Card.Title as="div" className="about-card-title">
                        <h3>Skilled Professionals</h3>
                      </Card.Title>

                      <Card.Text as="div" className="about-card-text">
                        <div className="my-3">
                          <p>
                            Our Virtual assistants are in high demand due to
                            their English language competence. The majority of
                            our virtual assistants are university-educated who
                            can perform tasks from research, data entry,
                            customer service, and sales support.
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={12} md={6} xl={4}>
                  <Card className="rounded text-center about-cards">
                    <Card.Img src={Leadership} variant="top" />
                    <Card.Body>
                      <Card.Title as="div" className="about-card-title">
                        <h3>Strong Work Ethics</h3>
                      </Card.Title>

                      <Card.Text as="div" className="about-card-text">
                        <div className="my-3">
                          <p>
                            All of our virtual assistants are hardworking and
                            competent with a great sense of responsibility.
                            Their dedication to their work is outstanding and
                            they take pride in their work. Our virtual
                            assistants don’t take employment for granted and
                            truly value their employers.
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={12} md={6} xl={4}>
                  <Card className="rounded text-center about-cards">
                    <Card.Img src={Expense} variant="top" />
                    <Card.Body>
                      <Card.Title as="div" className="about-card-title">
                        <h3>Less Cost, More Savings for the Business</h3>
                      </Card.Title>

                      <Card.Text as="div" className="about-card-text">
                        <div className="my-3">
                          <p>
                            Our virtual assistants allow you to improve
                            production for the company while spending less
                            money. Hiring a virtual assistant will eliminate
                            fees' for office space or office equipment and
                            supplies.
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </motion.div>
    </>
  );
};

export default Aboutscreen;
