import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';

const Applyscreen = () => {
  return (
    <>
      <Container>
        <Form
          className='pt-5'
          method='POST'
          action='https://formsubmit.co/khen0070@gmail.com'
          enctype='multipart/form-data'
        >
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Please Enter your Full Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Full Name'
              className='mb-3'
              name='Full Name'
              required='true'
            />

            <Form.Label>Please Enter your Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Email'
              name='email'
              required='true'
            />
            <Form.Text className='text-muted'>
              We'll never share your email with anyone else.
            </Form.Text>

            <Form.Control
              type='hidden'
              name='_next'
              value='https://serviceowl.vercel.app/thankyou'
            />

            <Form.Control type='hidden' name='_captcha' value='false' />
          </Form.Group>

          <Form.Group controlId='formFile' className='mb-3'>
            <Form.Label>Please Attach Your CV/Resume</Form.Label>
            <Form.Control name='attachment' type='file' required='true' />
          </Form.Group>

          <Button variant='primary' type='submit'>
            Submit
          </Button>

          <Form.Text className='text-muted mt-3'>
            Please give us 2-3 business days to check and assess your
            qualification. Thank you for you interest with{' '}
            <strong>"Service Owl"</strong>.
          </Form.Text>
        </Form>
      </Container>
    </>
  );
};

export default Applyscreen;
