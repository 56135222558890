import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const Stepscreen = () => {
  return (
    <>
      <h1 className="application-section-header text-center">
        GETTING YOUR VIRTUAL ASSISTANT
      </h1>
      <div>
        <p className="application-section-p text-center pb-3" variant="primary">
          Easy steps in getting you the best <strong>VIRTUAL ASSISTANT</strong>
        </p>

        {/* <div>
          <Row className="px-5">
            <Col md={3} className="application-steps text-center">
              <ListGroup variant="flush">
                <Image src={Apply} alt="" fluid />
                <ListGroup.Item className="pt-4">
                  <h3>1. Contact Us</h3>
                  <p>
                    Your ideal virtual assistant is now waiting. Let us know
                    which tasks require assistance.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={3} className="application-steps text-center">
              <ListGroup variant="flush">
                <Image src={Resume} alt="" fluid />
                <ListGroup.Item className="pt-4">
                  <h3>2. Sourcing</h3>
                  <p>
                    We will find suitable candidates based on your requirements
                    from our fast-growing list of talented Virtual Assistants.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3} className="application-steps text-center">
              <ListGroup variant="flush">
                <Image src={Training} alt="" fluid />
                <ListGroup.Item className="pt-4">
                  <h3>3. Testing</h3>
                  <p>
                    Tests and screening will be conducted to choose the best
                    match for your specifications.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={3} className="application-steps text-center">
              <ListGroup variant="flush">
                <Image src={Interview2} alt="" fluid />
                <ListGroup.Item className="pt-4">
                  <h3>4. Interview</h3>
                  <p>
                    A series of interviews will be conducted, one for the
                    initial screening and one for the client-virtual assistant.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </div> */}
      </div>

      <Container>
        <div className="steps_main_container">
          <Row className="steps_first_row">
            <Col md={6} xs={12}>
              <div className="box box1">
                <div className="box_left_section">
                  {" "}
                  <h1>1</h1>
                  <h3>Contact Us</h3>
                </div>
                <p>
                  Your ideal virtual assistant is now waiting. Let us know which
                  tasks require assistance.
                </p>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="box box2">
                <div className="box_left_section">
                  {" "}
                  <h1>2</h1>
                  <h3>Sourcing</h3>
                </div>
                <p>
                  We will find suitable candidates based on your requirements
                  from our fast-growing list of talented Virtual Assistants.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="steps_first_row">
            <Col md={6} xs={12}>
              <div className="box box3">
                <div className="box_left_section">
                  {" "}
                  <h1>3</h1>
                  <h3>Testing</h3>
                </div>
                <p>
                  Tests and screening will be conducted to choose the best match
                  for your specifications.
                </p>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="box box4">
                <div className="box_left_section">
                  {" "}
                  <h1>4</h1>
                  <h3>Interview</h3>
                </div>
                <p>
                  A series of interviews will be conducted, one for the initial
                  screening and one for the client-virtual assistant.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Stepscreen;
