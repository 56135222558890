import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/Homescreen";
import AboutScreen from "./screens/Aboutscreen";
import Applyscreen from "./screens/Applyscreen";
import Thankyouscreen from "./screens/Thankyouscreen";
import ThankyouReviewScreen from "./screens/ThankyouReviewScreen";
import ContactScreen from "./screens/ContactScreen";
import Reviewscreen from "./screens/Reviewscreen";
import ReviewFormScreen from "./screens/ReviewFormScreen";

import Servicesscreen from "./screens/Servicesscreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import Paymentscreen1 from "./screens/Paymentscreen1";
import Thankyoupaymentscreen from "./screens/Thankyoupaymentscreen";

import Paymentscreen2 from "./screens/Paymentscreen2";

import Paymentscreen from "./screens/Paymentscreen";

import Subscription from "./screens/Subscription";
import SubscriptionThankyou from "./screens/SubscriptionThankyou";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/Scrolltotop";

import PageNotFound from "./components/PageNotFound";

import { AnimatePresence } from "framer-motion";
import ServicesAdministrativeScreen from "./screens/ServicesAdministrativeScreen";
import ServicesRealEstateScreen from "./screens/ServicesRealEstateScreen";
import ServicesMedical from "./screens/ServicesMedical";
import ServicesPropertyManagement from "./screens/ServicesPropertyManagement";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />

      <main>
        <AnimatePresence>
          <Switch>
            <Route path="/" component={HomeScreen} exact />
            <Route path="/about" component={AboutScreen} exact />
            <Route
              path="/administrative"
              component={ServicesAdministrativeScreen}
              exact
            />
            <Route
              path="/realestate"
              component={ServicesRealEstateScreen}
              exact
            />
            <Route
              path="/propertymanagement"
              component={ServicesPropertyManagement}
              exact
            />
            <Route path="/medical" component={ServicesMedical} exact />

            <Route path="/apply" component={Applyscreen} exact />
            <Route path="/contact" component={ContactScreen} exact />
            <Route path="/thankyou" component={Thankyouscreen} exact />
            <Route
              path="/thankyoureview"
              component={ThankyouReviewScreen}
              exact
            />
            <Route path="/privacypolicy" component={PrivacyScreen} exact />
            <Route path="/reviews" component={Reviewscreen} exact />
            <Route path="/create_review" component={ReviewFormScreen} exact />

            <Route path="/services" component={Servicesscreen} exact />
            <Route path="/payment1" component={Paymentscreen1} exact />
            <Route path="/finalpayment" component={Paymentscreen2} exact />
            <Route
              path="/thankyou_payment"
              component={Thankyoupaymentscreen}
              exact
            />

            <Route path="/subscription" component={Subscription} exact />

            <Route
              path="/thankyou_subscription"
              component={SubscriptionThankyou}
              exact
            />

            <Route path="/payment" component={Paymentscreen} exact />

            <Route component={PageNotFound} />
          </Switch>
        </AnimatePresence>
      </main>

      <Footer />
    </Router>
  );
};

export default App;
